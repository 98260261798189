.wrapper {
  position: fixed;
  width: 100%;
  max-width: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
  overflow-y: auto;
  max-height: 90vh;
}

.head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  span {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
  }
}

.content {
  padding: 0px 12px;
  padding-bottom: 16px;
}

.btns {
  display: flex;
  align-items: center;

  .cancel {
    width: 100%;
  }
  .create {
    width: 100%;
  }
}

.layers {
  display: flex;
  padding: 8px 12px;
  width: 100%;
  border-bottom: 1px solid #e3e5fa;
  margin-bottom: 20px;
}
.line {
  width: 1px;
  height: auto;
  background: #abadc8;
  margin: 0px 12px;
}
.label {
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.items {
  display: flex;
  flex-wrap: wrap;
}
.item {
  // cursor: pointer;
  transition: all 0.2s;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  user-select: none;
  line-height: 24px; /* 171.429% */
  margin: 4px;
  border-radius: 8px;
  padding: 2px 8px;
  &.active {
    // background: #37cc8f;
  }
}
.combo {
  margin: 16px 0;
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;text-align: center;
}
