.toggle {
  transition: all 0.2s;
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background: #e0e2e6;
  cursor: pointer;
  &.active {
    background: #31b27e;
    .point {
      left: calc(100% - 20px - 2px);
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  &.hideText {
    .text {
      display: none;
    }
  }
}
.text {
  width: 62px;
  margin-right: 8px;
  color: #040503;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.point {
  transition: all 0.2s;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
