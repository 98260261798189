@font-face {
  font-family: "FuturaPT";
  src: url("./fonts/FuturaPT-Book.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "FuturaPT";
  src: url("./fonts/FuturaPT-Medium.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "FuturaPT";
  src: url("./fonts/FuturaPT-Heavy.woff2") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "FuturaPT";
  src: url("./fonts/FuturaPT-Bold.woff2") format("woff2");
  font-weight: 700;
}
