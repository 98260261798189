.wrapper {
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo {
  margin-bottom: 36px;
}
.content {
    width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 30px 24px;
}
.title {
  color: #343A40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 30px;
}
