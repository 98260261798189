.categories {
  padding: 19px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e5fa;
  width: 50%;

}
.titleAttr {
  position: relative;
  top: 0px;
  color: #343a40;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
}

.info {
  display: flex;
}
.activeCategoryWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
}
.activeCategories {
  transition: all 0.2s;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 8px;
  background: #e3e5fa;
  padding: 2px 8px;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-right: 12px;
  margin-bottom: 12px;
  .edit {
    transition: all 0.2s;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 4px;

    &:hover {
      transform: scale(1.2);
    }
  }
  .close {
    transition: all 0.2s;
    margin-left: 12px;
    display: flex;
    cursor: pointer;

    rect {
      fill: #e3e5fa;
    }
    &:hover {
      transform: rotate(90deg);
    }
  }
}

.addWrapper {
  position: relative;
}
.categories {
  &Btn {
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #6c757d;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    svg {
      transition: all 0.2s;
      margin-right: 4px;
    }
    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  &Content {
    transition: all 0.2s;
    min-width: 144px;
    max-height: 400px;
    overflow-y: auto;
    width: fit-content;
    position: absolute;
    top: 24px;
    right: 0;
    border-radius: 10px;
    border: 1px solid #e3e5fa;
    background: #fff;
    box-shadow: 0px 8px 36px 0px rgba(23, 23, 46, 0.16);
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    height: 0;
    padding: 0;

    &.active {
      visibility: visible;
      opacity: 1;
      z-index: 9;
      height: auto;
      padding: 8px;
    }
  }
  &Item {
    transition: all 0.2s;
    cursor: pointer;
    padding: 8px 12px;
    color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;

    &.disable {
      opacity: 0.6;
      pointer-events: none;
    }
    &:hover {
      background: #f6f7fd;
    }
  }
}

.create {
  transition: all 0.2s;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border-top: 1px solid #e3e5fa;

  svg {
    margin-right: 10px;
    path {
      fill: #37cc8f;
    }
  }
  &:hover {
    color: #37cc8f;
  }
}
