.modal {
  &_bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(23, 23, 46, 0.60);
    backdrop-filter: blur(4px);
    width: 100vw;
    height: 100vh;
  }
}
