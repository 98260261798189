.list {
    margin: 20px 0;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .optionWrapper {
    display: flex;
    align-items: center;
  }
  
  .text {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }
  
  .option {
    color: #6c757d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-right: 8px;
  }
  .name {
    padding: 2px 8px;
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    border-radius: 8px;
    background: #e3e5fa;
  }
  
  .select {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e3e5fa;
    background: #fff;
    cursor: pointer;
    &.active {
      .title {
        svg {
          transform: rotate(180deg);
        }
      }
      .content {
        visibility: visible;
        opacity: 1;
        height: auto;
        z-index: 99;
        margin-top: 15px;
      }
    }
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    svg {
      display: flex;
      transition: all 0.2s;
    }
    span {
      display: flex;
      color: #6c757d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.16px;
    }
  }
  
  .content {
    visibility: hidden;
    opacity: 0;
    height: 1px;
    z-index: -1;
    margin-top: 0px;
  }
  .item {
    transition: all 0.2s;
    cursor: pointer;
    color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-top: 8px;
  
    &:hover {
      color: #37cc8f;
    }
  }
  