.wrapper {
  position: relative;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0px;
  background: #fff;
  border: 1px solid transparent;
  background: #fff;
  border-bottom: 1px solid #e3e5fa;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
  }
  &.pointer {
    cursor: pointer;
  }
  .img,
  .title {
    opacity: 0.4;
    pointer-events: none;
  }
  &.eneble {
    .img,
    .title {
      pointer-events: all;
      opacity: 1;
    }
  }

  &:hover {
    border-radius: 8px;
    border: 1px solid #abadc8;
  }
  .dropdown {
    display: none;
    margin-left: 32px;
    cursor: pointer;
  }
  .grouped {
    display: flex;
    align-items: center;
  }
  &.children {
    border-radius: 8px;
    border: 1px solid #e3e5fa;
    background: #fff;
    margin: 12px;
    .dropdown {
      display: flex;
      svg {
        transition: all 0.2s;
      }
    }
    .item {
      padding: 12px;
      cursor: pointer;
    }

    .isMini {
      .grouped {
        flex-direction: column;
        align-items: start;
        margin-right: 0;
        .title {
          margin-bottom: 6px;
        }
      }
      .content {
        position: relative;
        padding-left: 60px;
      }
      .item {
        cursor: default;
      }
      .dropdown {
        display: none;
      }
      .toggle {
        position: absolute;
        left: 0;
      }
    }

    .children {
      transition: all 0.2s;
      padding: 0;
      position: relative;
      z-index: 9;
      border-radius: 10px;
      background: #fff;
      overflow-y: auto;
      height: auto;
      max-height: 340px;
      opacity: 0;
      height: 1px;
      visibility: hidden;
      width: 100%;
    }
    &.active {
      .dropdown {
        svg {
          transform: rotate(180deg);
        }
      }
      .children {
        padding: 12px;
        visibility: visible;
        opacity: 1;
        height: auto;
      }
    }
  }
}
.content {
  display: flex;
  align-items: center;
}
.type {
  border-radius: 8px;
  background: #4361ee;
  padding: 4px 12px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 26px;

  &.geometry {
    background: #eac159;
    color: #343a40;
  }
  &::first-letter {
    text-transform: uppercase;
  }
}
.subtitle {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #e3e5fa;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-right: 12px;
}
.events {
  display: flex;
  align-items: center;
}
.delete {
  margin-left: 12px;
}
.duplicate {
  margin-left: 12px;
}

.title {
  margin-right: 24px;
  color: #343a40;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.id {
  margin-right: 24px;
}

.img {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
