.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  border-bottom: 1px solid #e8e8ef;
  height: 77px;
  width: 100%;
}
.title {
  margin-left: 12px;
  color: #343A40;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}
.info {
  display: flex;
  align-items: center;
}
.close {
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    transition: all 0.2s;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
}

.tools {
  display: flex;
  align-items: center;
}