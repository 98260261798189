







@media (max-width: 1024px) {
    .wrapper {
      display: none;
    }
  }