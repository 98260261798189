.edit {
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
      path {
        transition: all 0.2s;
      }
    }
    span {
      transition: all 0.2s;
      color: #7d7d8a;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
      
    &.delete {
      span {
        color: #E41F2A;
      }
    }

    &:hover {
      span {
        color: #31b27e;
      }
      svg {
        path {
          fill: #31b27e;
        }
      }
    }
  }