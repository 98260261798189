.wrapper {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e3e5fa;
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.upperInfo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.bottomInfo {
  display: flex;
  align-items: center;
}
.item {
  margin-right: 32px;
}
.label {
  color: #6c757d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.value {
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.status {
  display: flex;
  align-items: center;

  .point {
    width: 4px;
    height: 4px;
    margin-right: 4px;

    background: #eac159;
    &.pending {
      background: #eac159;
    }
    &.success {
      background: #37cc8f;
    }
  }
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-bottom: 23px;
}

.controllers {
  display: flex;
  flex-direction: column;
  align-items: self-end;
}
.render {
  transition: 0.2s;
  cursor: pointer;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  padding: 8px 32px;
  border-radius: 50px;
  background: #e3e5fa;
  height: 34px;
  &:hover {
    background: #c8c9dd;
  }
  &.pending {
    pointer-events: none;
    padding: 6px 0px;
    width: 88px;
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      animation: rotate 2s linear infinite;
      width: 100%;
    }
    &:hover {
      background: #e3e5fa;
    }
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn {
  border-top: 23px;
  display: flex;
  align-items: center;
}
.text {
  margin-right: 12px;
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.delete {
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  color: #ff4b4b;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  svg {
    display: flex;
    margin-right: 4px;
  }
}
