
.wrapper {
  width: calc(100% - 430px);
}






@media (max-width: 1024px) {
    .wrapper {
      display: none;
    }
  }