.index {
  color: #343a40;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
}

.item {
  padding: 8px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #e3e5fa;
}
.lists {
  display: flex;
  flex-wrap: wrap;
}
.list {
  transition: all 0.2s;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #e3e5fa;
  margin-right: 12px;
  cursor: pointer;
  span {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    display: flex;
    margin-right: 12px;
  }

  &.repeat {
    background: #f4a261;
  }

  &:hover {
    background: #cdcfe2;
  }
}

.add {
  transition: all 0.2s;
  display: flex;
  border-radius: 4px;
  position: relative;
  padding: 0px 4px;
  cursor: pointer;
  svg {
    display: flex;
  }
  &:hover {
    background: #f6f7fd;
  }
  .head {
    display: flex;
    align-items: center;
    span {
      display: flex;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 8px;
    }
  }

  &.active {
    .content {
      top: 27px;
      z-index: 9;
      visibility: visible;
      height: auto;
    }
  }
}
.content {
  position: absolute;
  left: 0;
  min-width: 144px;
  border-radius: 10px;
  border: 1px solid #e3e5fa;
  padding: 4px;
  background: #fff;
  box-shadow: 0px 8px 36px 0px rgba(23, 23, 46, 0.16);
  top: 0;
  z-index: -1;
  visibility: hidden;
  height: 0;
  &Item {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    padding: 12px 8px;
    transition: all 0.2s;

    &:hover {
      border-radius: 8px;
      background: #f6f7fd;
    }
  }
}
