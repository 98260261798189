.wrapper {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
  margin-right: 12px;
  
}
.label {
  color: #2a8efa;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-right: 4px;
}
