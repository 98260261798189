.edit {
}

.head {
  padding: 16px 24px;
  border-bottom: 1px solid #e3e5fa;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.back {
  transition: all .2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */

  svg {
    margin-right: 4px;
    path {
      transition: all .2s;
    }
  }

  &:hover {
    color: #37CC8F;

    svg {
      path {
        fill: #37CC8F;
      }
    }
  }
}

.content {
  display: flex;
  align-items: center;
}
.name {
  color: #343a40;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 24px;
}

.items {
  padding: 12px 24px;
  height: calc(100vh - 210px);
}