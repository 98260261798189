.notiflix-notify-success .nx-message-icon {
  opacity: 0;
}

.notiflix-notify-success::after {
  position: absolute;
  left: 20px;
  content: url("../../assets/images/other/success.svg");
  width: 24px;
  height: 24px;
}
.notiflix-notify-success::before {
  position: absolute;
  right: 12px;
  top: 21px;
  content: url("../../assets/images/other/close.svg");
  width: 24px;
  height: 24px;
}

.notiflix-notify-failure .nx-message-icon {
  opacity: 0;
}

.notiflix-notify-failure::after {
  position: absolute;
  left: 20px;
  content: url("../../assets/images/other/error.svg");
  width: 24px;
  height: 24px;
}
.notiflix-notify-failure::before {
  position: absolute;
  right: 12px;
  top: 21px;
  content: url("../../assets/images/other/close.svg");
  width: 24px;
  height: 24px;
}

.notiflix-notify-warning .nx-message-icon {
  opacity: 0;
}

.notiflix-notify-warning::after {
  position: absolute;
  left: 20px;
  content: url("../../assets/images/other/error.svg");
  width: 24px;
  height: 24px;
}
.notiflix-notify-warning::before {
  position: absolute;
  right: 12px;
  top: 21px;
  content: url("../../assets/images/other/close.svg");
  width: 24px;
  height: 24px;
}

.notiflix-notify-info .nx-message-icon {
  opacity: 0;
}

.notiflixWrapper {
  background: #1c1c1c !important;
}
.notiflixWrapper span {
  color: #fff !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important; /* 157.143% */
  padding-right: 30px !important;
}
.notiflix-notify-info::after {
  position: absolute;
  left: 20px;
  content: url("../../assets/images/other/info.svg");
  width: 24px;
  height: 24px;
}
.notiflix-notify-info::before {
  position: absolute;
  right: 12px;
  top: 21px;
  content: url("../../assets/images/other/close.svg");
  width: 24px;
  height: 24px;
}
