.wrapper {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  label {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-bottom: 6px;
  }
  .req {
    position: relative;
    top: 0;
    right: -4px;
    color: #ff4b4b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .input {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    padding: 18px 20px;
    border-radius: 8px;
    border: 1px solid #e8e8ef;
    background: #fff;
    color: #6c757d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 100%;
    svg {
      transition: all 0.2s;
      position: absolute;
      right: 20px;
    }
  }

  .content {
    position: relative;
    z-index: 9;
    border-radius: 10px;
    border: 1px solid #e3e5fa;
    background: #fff;
    box-shadow: 0px 8px 36px 0px rgba(23, 23, 46, 0.16);
    top: 10px;
    overflow-y: auto;
    height: auto;
    max-height: 200px;
    opacity: 0;
    width: 144px;
    height: 1px;
    visibility: hidden;
    width: 100%;
  }
  .item {
    transition: all 0.2s;
    cursor: pointer;
    color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;

    &:hover {
      background: #eaecfd;
    }

    &.disable {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &.active {
    cursor: default;
    .input {
      svg {
        transform: rotate(180deg);
      }
    }
    .content {
      visibility: visible;
      opacity: 1;
      padding: 8px;
      height: auto;

      .item {
        padding: 8px 12px;
      }
    }
  }
}
.place {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
}
.attr {
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #e3e5fa;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  svg {
    transition: all 0.2s;
    margin-left: 12px;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
