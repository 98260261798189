.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

}
.svg {
  display: flex;
  width: 50px;
  height: 50px;
}
.svg svg {
  -webkit-animation: rotate-90-cw 6s infinite;
  animation: rotate-90-cw 6s infinite;
  transform-origin: center;
}

@-webkit-keyframes rotate-90-cw {
  0% {
    transform: rotate(0) scale(1);
    transition-timing-function: ease-in;
  }
  11% {
    transform: rotate(0turn) scale(1.5);
    transition-timing-function: ease-in;
  }
  22% {
    transform: rotate(0turn) scale(2);
    transition-timing-function: ease-out;
  }
  33% {
    transform: rotate(0.25turn) scale(2);
    transition-timing-function: ease-out;
  }
  44% {
    transform: rotate(0.5turn) scale(2);
    transition-timing-function: ease-out;
  }
  55% {
    transform: rotate(0.7turn) scale(2);
    transition-timing-function: ease-out;
  }
  66% {
    transform: rotate(1turn) scale(2);
    transition-timing-function: ease-out;
  }
  77% {
    transform: rotate(1turn) scale(1.5);
    transition-timing-function: ease-out;
  }
  88% {
    transform: rotate(1turn) scale(1);
    transition-timing-function: ease-out;
  }

  100% {
    transform: rotate(1turn);
    transition-timing-function: ease-out;
  }
}
@keyframes rotate-90-cw {
  0% {
    transform: rotate(0) scale(1);
    transition-timing-function: ease-in;
  }
  11% {
    transform: rotate(0turn) scale(1.5);
    transition-timing-function: ease-in;
  }
  22% {
    transform: rotate(0turn) scale(2);
    transition-timing-function: ease-out;
  }
  33% {
    transform: rotate(0.25turn) scale(2);
    transition-timing-function: ease-out;
  }
  44% {
    transform: rotate(0.5turn) scale(2);
    transition-timing-function: ease-out;
  }
  55% {
    transform: rotate(0.7turn) scale(2);
    transition-timing-function: ease-out;
  }
  66% {
    transform: rotate(1turn) scale(2);
    transition-timing-function: ease-out;
  }
  77% {
    transform: rotate(1turn) scale(1.5);
    transition-timing-function: ease-out;
  }
  88% {
    transform: rotate(1turn) scale(1);
    transition-timing-function: ease-out;
  }

  100% {
    transform: rotate(1turn);
    transition-timing-function: ease-out;
  }
}
