.wrapper {
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;

  &.active {
    svg {
      opacity: 1;
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #e3e5fa;

  svg {
    transition: all .2s;
    opacity: 0;
  }
}
.name {
  margin-left: 4px;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
