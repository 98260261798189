.id {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #e3e5fa;

  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */

  svg {
    margin-left: 4px;
  }
}
