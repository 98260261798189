.wrapper {
  overflow-y: auto;
  max-height: 100vh;
  position: fixed;
  width: 100%;
  max-width: 750px;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
}

.head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  span {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
  }
}

.content {
  padding: 0px 12px;
  padding-bottom: 16px;
}

.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.tab {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border-top: 1px solid #e3e5fa;
  border-right: 1px solid #e3e5fa;
  border-left: 1px solid #e3e5fa;
  border-bottom: 1px solid #e3e5fa;
  cursor: pointer;
  padding: 12px;
  color: #6c757d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  &.active {
    // border-radius: 8px 0px 0px 8px;
    color: #343a40;
    font-weight: 600;
    background: #37cc8f;
  }
  &.tabLeft {
    &.active {
      border-radius: 8px 0px 0px 8px;
      color: #343a40;
      font-weight: 600;
      background: #37cc8f;
    }
  }
  &.tabRight {
    &.active {
      border-radius: 0px 8px 8px 0px;
      color: #343a40;
      font-weight: 600;
      background: #37cc8f;
    }
  }

  &First,
  &Second,
  &Third {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    height: 0;

    &.active {
      opacity: 1;
      z-index: 9;
      visibility: visible;
      height: auto;
    }
  }
}

.category {
  &Content {
    position: relative;
    .plus {
      position: absolute;
      right: 15px;
      top: 50px;
    }
  }
  &Select {
    margin-bottom: 10px;
  }
  &Text {
    color: #6c757d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 21px;
    &:hover {
      .plus {
        background: #33c186;
      }
    }
  }
  .plus {
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #37cc8f;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    &:hover {
      background: #33c186;
    }
  }
}

.hint {
  position: relative;
  top: -23px;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 6px;
  display: flex;
  align-items: center;
  .red {
    color: #ff4b4b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    margin-right: 4px;
  }
}
