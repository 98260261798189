.wrapper {
  position: relative;
  border-radius: 10px 0px 0px 0px;
  border-right: 1px solid #e8e8ef;
  background: #fff;
  width: 430px;
  padding-bottom: 84px;
}

.title {
  padding: 26px 24px;
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  border-bottom: 1px solid #e8e8ef;
}

.content {
  position: relative;
  z-index: 1;
  margin-top: 16px;
  padding: 0px 16px;
  overflow-y: auto;
  height: calc(100% - 93px);
}
.btns {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-between;
}

.item {
  transition: all 0.2s;
  padding: 20px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8ef;
  background: #fff;
  justify-content: space-between;
  border-radius: 0px;
  &:hover {
    background: #e3e5fa;
    border-radius: 8px;
  }
  &.active {
    background: #e3e5fa;
    border-radius: 8px;
  }

  .name {
    color: #343a40;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.add {
  z-index: 99;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #e8e8ef;
}
