.wrapper {
  position: fixed;
  width: 100%;
  max-width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 40px 0px rgba(22, 21, 22, 0.2);
}

.type {
  margin-bottom: 20px;
}

.name {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  margin-bottom: 6px;
}
.req {
  position: relative;
  top: 0;
  right: -4px;
  color: #ff4b4b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.type {
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;
  }
  &Name {
    color: #343a40;
    font-family: "Futura PT";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
  }
}

.head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  span {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 15.4px */
  }
}

.content {
  padding: 0px 12px;
  padding-bottom: 16px;
}
