.selectItem {
  position: relative;
  padding: 8px 16px;
  border-bottom: 1px solid #e3e5fa;
}
.headSelect {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #abadc8;
  cursor: pointer;
}
.name {
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
}
.add {
  display: flex;
  align-items: center;
  position: relative;
  top: 1px;
  svg {
    transition: all 0.2s;
    transform: rotate(45deg);
    margin-right: 8px;
    display: flex;
  }
  span {
    color: #6c757d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:hover {
    svg {
      transform: rotate(45deg) scale(1.2);
    }
  }
}

.optionSelect {
  transition: all 0.1s;
  position: absolute;
  top: 0px;
  left: 0;
  background: #fff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e5fa;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  height: 0;

  &.active {
    top: 40px;
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}
.opt {
  transition: all 0.2s;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background: #65ecb4;
  }
  &.disable {
    opacity: 0.4;
    pointer-events: none;
  }
}
.optionsList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px -4px;
  margin-top: 12px;
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background: #e3e5fa;
  width: fit-content;
  margin: 4px;
  user-select: none;
  span {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  svg {
    transition: all 0.2s;
    margin-left: 12px;
  }
  &.disable {
    pointer-events: none;
    cursor: inherit !important;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
