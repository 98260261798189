.wrapper {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8ef;
  margin-bottom: 12px;

  height: 81px;
}
.tabs {
  display: flex;
  align-items: center;
}

.tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  color: #6c757d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  background: #fff;
  padding: 12px;
  border: 1px solid #e8e8ef;
  border-right: unset;

  &:first-child {
    border-radius: 10px 0px 0px 10px;
  }

  &:last-child {
    border-radius: 0px 10px 10px 0px;
    border-right: 1px solid #e8e8ef;
  }

  &:hover {
    transition: all 0.2s;
    background: #e3e5fa;
  }
  &.active {
    background: #31b27e;
    color: #343a40;
    font-weight: 600;
    border: 1px solid #31b27e;
    .count {
      background: #fff;
      color: #343a40;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.count {
  padding-top: 1px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 20px;
  height: 17px;
  border-radius: 50%;
  background: #6c757d;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
}
