.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  label {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    margin-bottom: 6px;
  }
  .req {
    position: relative;
    top: 0;
    right: -4px;
    color: #ff4b4b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  input {
    outline: none;
    padding: 18px 20px;
    border-radius: 8px;
    border: 1px solid #e8e8ef;
    background: #fff;
    color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
.hint {
  color: #6c757d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
