.content {
  display: flex;
  height: calc(100vh);
}
.sidebar {
  width: 120px;
  height: 100%;
  padding: 32px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e3e5fa;
  background: #fff;
}
.logo {
  width: 96px;
  cursor: pointer;
  svg {
    width: 100%;
  }
}
.navigate {
  margin-top: 64px;
}
.link {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  svg {
    margin-right: 8px;
    path {
      transition: all 0.2s;
    }
  }
  &:hover {
    svg {
      path {
        fill: #37cc8f;
      }
    }
    color: #37cc8f;
  }
}
.body {
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  width: calc(100vw - 120px);
  border-radius: 10px 10px 0px 0px;
  height: 100%;
  background: #f6f7fd;
}
.child {
  border: 1px solid #e3e5fa;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
}
.info {
}
.title {
  color: #343a40;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 85.714% */
  margin-top: 30px;
  margin-bottom: 4px;
}
.mail {
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.logout {
  cursor: pointer;
  display: flex;
  align-items: center;
justify-content: center;
  svg {
    margin-right: 4px;
    path {
      fill: #343a40;
      transition: all 0.2s;
    }
  }
  span {
    transition: all 0.2s;
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  &:hover {
    span {
      color: #31b27e;
    }
    svg {
      path {
        fill: #31b27e;
      }
    }
  }
}
