.wrapper {
  display: flex;
  align-items: center;
}
.coord {
  &:first-child {
    margin-right: 20px;
  }
  &Title {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-bottom: 6px;
    .req {
      position: relative;
      top: 0;
      right: -4px;
      color: #ff4b4b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &Wrapper {
    display: flex;
    align-items: center;
  }
}
.singleCoord {
  .rotation {
    width: 130px;
  }
}

.position,
.rotation {
  width: 62px;
  margin-right: 2px;

  input {
    padding: 20px 7px;
    text-align: center;
  }
}
