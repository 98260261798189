.hint {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 200px);
  color: #686868;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
