.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 1024px) {
  .wrapper {
    display: none;
  }
}
