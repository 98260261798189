.edit {
}

.head {
  padding: 16px 24px;
  border-bottom: 1px solid #e3e5fa;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.wrap {
  display: flex;
  align-items: center;
}
.renderWRapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.mount {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin: 0px 12px;
}
.back {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */

  svg {
    margin-right: 4px;
    path {
      transition: all 0.2s;
    }
  }

  &:hover {
    color: #37cc8f;

    svg {
      path {
        fill: #37cc8f;
      }
    }
  }
}

.content {
  display: flex;
  align-items: center;
}
.name {
  color: #343a40;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 24px;
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 315px);
}
.options {
  border-right: 1px solid #e3e5fa;
}
.stages,
.options {
  overflow-y: auto;
  max-height: calc(100%);
  overflow-x: hidden;
  height: 100%;
  width: 50%;
}

.id {
  margin-right: 24px;
}
.titleAttr {
  padding: 16px 24px;
  border-bottom: 1px solid #e3e5fa;
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
