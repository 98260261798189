.wrapper {
  position: relative;
  width: 50%;

  &.hide {
    display: none;
  }
  &.active {
    width: 100%;
    display: block !important;
  }
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid #e3e5fa;
  border-right: 1px solid #e3e5fa;
}

.title {
  color: #343a40;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.btn {
  transition: all 0.2s;
  color: #343a40;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: flex;
  align-items: center;
  border-radius: 50px;
  width: 60px;
  background: #e3e5fa;
  user-select: none;
  cursor: pointer;
  padding: 4px 8px;
  svg {
    transition: all 0.2s;
    width: 14px;
  }
  &:hover {
    background: #c8cade;
  }
  &.close {
    background: #6c757d;
    color: #fff;
    svg {
      path {
        stroke: #fff;
      }
    }
    &:hover {
      background: rgb(145, 146, 162);
    }
  }
  span {
    display: flex;
    margin-left: 2px;
  }
}
.wrap {
  display: flex;
  align-items: center;
}
.hint {
  color: #6c757d;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 12px;
}

.content {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 240px);
  padding: 10px 24px;
  padding-bottom: 74px;
  width: 100%;
  padding-bottom: 60px;
}

.items {
  padding: 10px 0px;
  height: calc(100vh - 310px);
  overflow-y: auto;
}

.unused {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 24px;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e5fa;
  &Items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .item {
    transition: all 0.2s;
    cursor: pointer;
    padding: 2px 8px;
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-right: 4px;
    border-radius: 8px;
    background: #e3e5fa;

    &:hover {
      background: #d3d5ea;
    }
  }
}
.label {
  color: #343a40;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-right: 12px;
  line-height: normal;
}
