.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  label {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    margin-bottom: 6px;
  }
  .req {
    position: relative;
    top: 0;
    right: -4px;
    color: #ff4b4b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  input {
    outline: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.text {
  color: #2a8efa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 12px;
}
.place {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 6px 0;
  padding: 20px 50px;
  min-height: 180px;
  border-radius: 10px;
  border: 2px dashed #e3e5fa;
  background: #fff;
}

.title {
  color: #343a40;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 15px 0;

  .blue {
    color: #2a8efa;
  }
}

.file {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  svg {
    margin-right: 8px;
  }
  border-radius: 10px;
  background: #f6f7fd;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.info {
}
.name {
  color: #343a40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 4px;
}
.size {
  .name {
    color: #343a40;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
  }
}
