.btn {
  position: relative;
  transition: all 0.2s;
  padding: 20px;
  border-radius: 50px;
  cursor: pointer;
  width: 100%;
  background: #37cc8f;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
    path {
      transition: all 0.2s;
      fill: #343a40;
    }
  }
  &.primary {
    transition: all 0.2s;
    height: 60px;
  }
  &.outlane {
    transition: all 0.2s;
    padding: 13px 20px;
    height: 48px;
  }
  &.transparent {
    background: transparent !important;
    border: 1px solid #343a40 !important;
  }
  &.warning {
    background: #ff4b4b !important;
    border: 1px solid #ff4b4b;
    span {
      color: #fff !important;
    }
  }
  span {
    transition: all 0.2s;
    color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  &:hover {
    background: #2ea776;
  }

  .arrow {
    display: flex;
    align-items: center;

    svg {
      transition: all 0.2s;
    }
    margin-left: 10px;
  }

  .content {
    position: absolute;
    z-index: 9;
    border-radius: 10px;
    border: 1px solid #e3e5fa;
    background: #fff;
    box-shadow: 0px 8px 36px 0px rgba(23, 23, 46, 0.16);
    bottom: -100px;
    opacity: 0;
    width: 144px;
    height: 1px;
    visibility: hidden;
  }
  .item {
    transition: all 0.2s;
    cursor: pointer;
    color: #343a40;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;

    &:hover {
      background: #eaecfd;
    }

    &.disable {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &.active {
    cursor: default;
    .arrow {
      svg {
        transform: rotate(180deg);
      }
    }
    .content {
      visibility: visible;
      opacity: 1;
      padding: 8px;
      height: auto;

      .item {
        padding: 8px 12px;
      }
    }
    &:hover {
      background: #37cc8f;
    }
  }
}
